import React, { useEffect, useState } from 'react';
import '../css/index.scss';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import MyLoading from '../../../../templates/MyLoading';
import { COLORS, ROLES } from '../../../../helpers/constants';
import useTranslation from '../../../../translations';
import MySelect from '../../../../templates/MySelect';
import MyInput from '../../../../templates/MyInput';
import useInputState from '../../../../helpers/hooks/useInputState';
import MyButton from '../../../../templates/MyButton';
import Api from '../../../../Api';
import CancelButton from '../../../../components/CancelButton';
import { saveActRequest } from '../../../../store/actions/acts';
import MyCheckbox from '../../../../templates/MyCheckbox';

function EditAct({ paramsId }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const role = useSelector((store) => store.account.role);
  const act = useSelector((store) => store.acts.singleAct);
  const gettingLoading = useSelector((store) => store.acts.gettingSingleActLoading);
  const gettingError = useSelector((store) => store.acts.gettingSingleActError);

  const savingLoading = useSelector((store) => store.acts.savingActLoading);
  const savingError = useSelector((store) => store.acts.savingActError);

  const [priority, setPriority] = useState('low');

  const [actions, setActions, actionsError, setActionsError] = useInputState([]);
  const [prescriptionItems, setPrescriptionItems, prescriptionItemsError, setPrescriptionItemsError] = useInputState([]);

  const [signatoryUsers, setSignatoryUsers] = useState([]);
  const [signatoryUser, setSignatoryUser, signatoryUserError, setSignatoryUserError] = useInputState();

  const [contractorComment, setContractorComment, contractorCommentError, setContractorCommentError] = useInputState();
  const [customerComment, setCustomerComment, customerCommentError, setCustomerCommentError] = useInputState();

  const [toSave, setToSave] = useState(false);

  useEffect(() => {
    (async () => {
      if (act?.['predpisan-items']) {
        setPriority(act['predpisan-priority']);

        setActions(act['act-actions'].map((item) => ({
          ...item,
          text: item.title,
        })), true);
        setPrescriptionItems(act['predpisan-items'].map((item) => ({
          ...item,
          'flag-fix': item['act-id'] === act?.id && !!item['act-id'],
        })), true);

        setContractorComment(act['remarks-podr'], true);
        setCustomerComment(act['remarks-strm'], true);

        if (act['signatory-user-alc-id']) {
          setSignatoryUser(act['signatory-user-alc-id']);
        }
      }

      if (!act?.['predpisan-items'] || act?.['predpisan-priority'] === 'low') return;
      const { data: sign } = await Api.getAllocs({
        'filter-val': {
          'build-comp-id': act['build-comp-id'],
          'is-constructor': true,
          'project-id': act['project-id'],
        },
        limit: 100,
        offset: 0,
        'page-number': 0,
        'search-val': '',
      });
      setSignatoryUsers(sign);
    })();
  }, [act]);

  useEffect(() => {
    if (!actions?.length || (actions?.length && actions[actions.length - 1]?.text)) {
      setActions((prev) => ([...prev, { text: '' }]), true);
    }
  }, [actions]);

  const updateActions = (index) => (e) => {
    const newActions = actions.map((item, i) => {
      if (index === i) {
        let { value } = e.target;
        if (e.target.files && e.target.files.length) {
          [value] = e.target.files;
        }
        return { ...item, [e.target.name]: value };
      }
      return item;
    });
    setActions(newActions, true);
  };

  const actionsFocusOut = (index) => (ev) => {
    if (!ev.target.value && index !== actions.length - 1) {
      setActions((prev) => prev.filter((el, i) => i !== index), true);
    }
  };

  const removeAction = (index) => () => {
    const actionToDelete = actions.find((el, i) => i === index);
    actionToDelete['flag-del'] = true;
    setActions([...actions], true);
  };

  const handleItems = (index) => () => {
    const newItems = prescriptionItems.map((item, i) => {
      if (index === i) {
        return { ...item, 'flag-fix': !item['flag-fix'] };
      }
      return item;
    });

    setPrescriptionItems(newItems, true);
  };

  const save = (status = 'draft') => {
    const isPriority = priority !== 'low';
    const files = [];

    const formattedActions = actions.filter(({ text }) => text.trim()).map((item, index) => {
      const uniqueId = uuidv4();
      if (item.file) {
        files.push({ uid: uniqueId, file: item.file });
      }

      const data = {
        'flag-del': false,
        ...item,
        title: item.text,
        'action-id': item['act-action-id'],
        'sort-number': index,
        'temp-uid': '',
        'picture-file': item['picture-file'] || '',
      };

      if (item.file?.name) {
        data['picture-file'] = item.file.name;
        data['temp-uid'] = uniqueId;
      }

      delete data.file;
      delete data.udate;
      delete data.cdate;

      return data;
    });

    const formattedItems = prescriptionItems.map((item) => ((item['act-id'] === null || item['act-id'] === act?.id) ? {
      ...item,
      'flag-fix': undefined,
      'act-id': item['flag-fix'] ? act?.id : null,
    } : undefined)).filter((item) => item);

    const closedItemsCount = prescriptionItems.filter((item) => item['flag-fix']).length;

    if (status !== 'draft') {
      let flag = false;

      if (isPriority) {
        if (!signatoryUser) {
          setSignatoryUserError(t('signatory_user') + t('is_required_m_r'));
          flag = true;
        }
      }

      if (!formattedActions.length) {
        setActionsError(t('fill_one'));
        flag = true;
      }

      if (!closedItemsCount) {
        setPrescriptionItemsError(t('close_one'));
        flag = true;
      }

      if (flag) return;
    }

    const payload = {
      id: act.id,
      'act-actions': formattedActions,
      'act-predpisan-items': formattedItems,
      'remarks-podr': contractorComment,
      'remarks-strm': customerComment,
      status,

      'build-obj-title': act['build-obj-title'],
      'project-title': act['project-title'],
      'project-id': act['project-id'],
      'engineer-user-alc-id': act['engineer-user-alc-id'],
      'engineer-user-alc': act['engineer-user-alc'],

      'signatory-user-alc-id': signatoryUser,

      'predpisan-id': act['predpisan-id'],
      'number-out': act['number-out'],
      'scan-file': null,
      title: '',

      notify_engineer: role === ROLES.constructor,
    };

    console.log({ payload });
    const formData = new FormData();
    formData.append('body', JSON.stringify(payload));

    files.forEach(({ uid, file }) => {
      formData.append(uid, file);
    });

    setToSave(true);
    dispatch(saveActRequest(formData));
  };

  useEffect(() => {
    if (!savingLoading && toSave) {
      if (!savingError) {
        navigate('..', { replace: true });
      } else {
        setToSave(false);
      }
    }
  }, [savingLoading]);

  return (
    gettingLoading ? <MyLoading color={COLORS.main} /> : gettingError ? (
      <p>
        {t('no_act_with_id')}
        {paramsId}
      </p>
    ) : (
      <div>
        <div className="single_act_item row">
          <p className="act_item_title act_margin_right">
            {t('engineer')}
            :
          </p>
          <p className="act_simple_text">
            {act && act['engineer-user-alc']?.fio}
          </p>
        </div>
        <div className="single_act_item row">
          <p className="act_item_title act_margin_right">
            {t('project')}
            :
          </p>
          <p className="act_simple_text">
            {act && act['project-title']}
          </p>
        </div>
        <div className="single_act_item row">
          <p className="act_item_title act_margin_right">
            {t('object')}
            :
          </p>
          <p className="act_simple_text">
            {act && `${act['build-obj']?.address} (${act['build-obj-title']})`}
          </p>
        </div>
        <div className="single_act_item row">
          <p className="act_item_title act_margin_right">
            {t('number')}
            :
          </p>
          <p className="act_simple_text">
            {act && act['number-out']}
          </p>
        </div>
        <div className="line" />

        <div className="single_act_item_big">
          <p className="act_item_title act_margin_right">
            {t('close_items')}
            :
          </p>
          {prescriptionItems?.length && prescriptionItems.map((item, index) => (
            <div className="prescription_item default_size" key={item['item-id']}>
              <MyCheckbox onChange={handleItems(index)} value={(item['act-id'] && item['act-id'] !== act?.id) || item['flag-fix']} disabled={item['act-id'] && item['act-id'] !== act?.id} label={`${index + 1}. ${item.title}`} rightLabel={moment(item['date-exp']).format('DD.MM.YYYY')} />
            </div>
          ))}
          {prescriptionItemsError && <p className="prescription_item_error">{prescriptionItemsError}</p>}
        </div>

        <div className="single_act_item_big">
          <p className="act_item_title act_margin_right">
            {t('activities_and_actions')}
            :
          </p>
          {actions?.length && actions.map((action, index) => (
            !action['flag-del'] && (
              <div className="row" key={index}>
                <MyInput name="text" error={actionsError} className="input_main" wrapperClassName="input_item default_size" value={action.text} onChange={updateActions(index)} onBlur={actionsFocusOut(index)} />
                {action.text && (
                  <MyInput name="file" wrapperClassName="input_item secondary" onChange={updateActions(index)} type="file" accept="image/png, image/gif, image/jpeg" />
                )}
                {action.text && <MyButton className="close_button" iconUri="/images/close.png" onClick={removeAction(index)} />}
                {!action.file && action['picture-file'] && (
                  <img className="action_image" src={`${Api.url}/api/image/act/26d26ef8-4d7b-4706-b9ed-2bff1d50043a/${action['act-action-id']}`} alt="" />
                )}
              </div>
            )
          ))}
        </div>

        <div className="single_act_item_big">
          <p className="act_item_title act_margin_right">
            {t('contr_comment')}
            :
          </p>
          <MyInput textarea className="big_input" value={contractorComment} onChange={setContractorComment} error={contractorCommentError} />
        </div>
        <div className="single_act_item_big">
          <p className="act_item_title act_margin_right">
            {t('customer_comment')}
            :
          </p>
          <MyInput textarea className="big_input" value={customerComment} onChange={setCustomerComment} error={customerCommentError} />
        </div>

        {priority !== 'low' ? (
          <div className="single_act_item_big">
            <p className="act_item_title act_margin_right">
              {t('signatory_user')}
              :
            </p>
            <MySelect wrapperClassName="default_size" data={signatoryUsers?.items} showKey={['fio-short', 'user-uid', 'role']} buttonText={t('choose_signatory')} error={signatoryUserError} value={signatoryUser} onChange={setSignatoryUser} onlyDropDown t={t} />
          </div>
        ) : (
          <div className="single_act_item_big">
            <p className="comment">{t('no_sign_needed')}</p>
          </div>
        )}
        <div className="row">
          {role === ROLES.stroymanager ? (
            <>
              {priority === 'low' ? (
                <MyButton text={t('save_active_force')} onClick={() => save('accept-force')} loading={savingLoading} />
              ) : (
                <MyButton text={t('save_signing')} onClick={() => save('signing')} loading={savingLoading} />
              )}
              <MyButton text={t('save_as_draft')} onClick={() => save('draft')} className="margin_left" loading={savingLoading} />
              <CancelButton loading={savingLoading} />
            </>
          ) : (
            <MyButton text={t('save_as_draft_and_notify')} onClick={() => save('draft')} loading={savingLoading} />
          )}

        </div>
        <div style={{ height: 100 }} />
      </div>
    )
  );
}

export default EditAct;
