function getUniqueByKey(arr, key) {
  if (!Array.isArray(arr)) return arr;

  const seen = new Set();
  return arr.filter((obj) => {
    const value = obj[key];
    if (seen.has(value)) {
      return false; // Filter out duplicates
    }
    seen.add(value);
    return true; // Keep unique objects
  });
}

export default getUniqueByKey;
