export const GET_OBJECTS_REQUEST = 'GET_OBJECTS_REQUEST';
export const GET_OBJECTS_SUCCESS = 'GET_OBJECTS_SUCCESS';
export const GET_OBJECTS_FAIL = 'GET_OBJECTS_FAIL';
export function getObjectsRequest(data) {
  return {
    type: GET_OBJECTS_REQUEST,
    payload: data,
  };
}

export const SET_SINGLE_OBJECT = 'SET_SINGLE_OBJECT';
export function setSingleObject(data) {
  return {
    type: SET_SINGLE_OBJECT,
    payload: data,
  };
}

export const SAVE_OBJECT_REQUEST = 'SAVE_OBJECT_REQUEST';
export const SAVE_OBJECT_SUCCESS = 'SAVE_OBJECT_SUCCESS';
export const SAVE_OBJECT_FAIL = 'SAVE_OBJECT_FAIL';
export function saveObjectRequest(data) {
  return {
    type: SAVE_OBJECT_REQUEST,
    payload: data,
  };
}

export const DELETE_OBJECT_REQUEST = 'DELETE_OBJECT_REQUEST';
export const DELETE_OBJECT_SUCCESS = 'DELETE_OBJECT_SUCCESS';
export const DELETE_OBJECT_FAIL = 'DELETE_OBJECT_FAIL';
export function deleteObjectRequest(data) {
  return {
    type: DELETE_OBJECT_REQUEST,
    payload: data,
  };
}

export const ARCHIVE_OBJECT_REQUEST = 'ARCHIVE_OBJECT_REQUEST';
export const ARCHIVE_OBJECT_SUCCESS = 'ARCHIVE_OBJECT_SUCCESS';
export const ARCHIVE_OBJECT_FAIL = 'ARCHIVE_OBJECT_FAIL';
export function archiveObjectRequest(data) {
  return {
    type: ARCHIVE_OBJECT_REQUEST,
    payload: data,
  };
}

export const RECOVER_OBJECT_REQUEST = 'RECOVER_OBJECT_REQUEST';
export const RECOVER_OBJECT_SUCCESS = 'RECOVER_OBJECT_SUCCESS';
export const RECOVER_OBJECT_FAIL = 'RECOVER_OBJECT_FAIL';
export function recoverObjectRequest(data) {
  return {
    type: RECOVER_OBJECT_REQUEST,
    payload: data,
  };
}
