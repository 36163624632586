import React, { useEffect, useState } from 'react';
import './css/index.scss';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MyButton from '../MyButton';
import SortTypeFilter from '../../components/SortTypeFilter';
import DescFilter from '../../components/DescFilter';
import stringToBoolean from '../../helpers/stringToBoolean';
import useNavigateSearch from '../../helpers/hooks/useNavigateSearch';
import nullInString from '../../helpers/nullInString';
import MyInput from '../MyInput';
import { t } from '../../translations';
import MyModal from '../MyModal';
import MyCheckbox from '../MyCheckbox';

function SortableList({
  data,
  renderItem,
  renderItemPayload,
  TitleButton,
  HeaderRow,
  requestData,
  loading,
  sortTypes,
  limitOnEachPage = 50,
  onItemEdit,
  labelToShowOnDelete,
  keyToShowOnDelete = 'title',
  onItemClick,
  onDeleteClick,
  onArchiveClick,
  onRecoverClick,
  additionalCLick,
  withSorting,
  checkBoxInfo,
  blockNotAdmin = true,
  withRefresh = true,
  withSearch = true,
  withHeader = true,
}) {
  const isAdmin = useSelector((store) => store.account.isAdmin);

  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const navigateSearch = useNavigateSearch();

  const searchQueries = {
    search_val: nullInString(searchParams.get('search_val')),
    page: nullInString(searchParams.get('page')),
  };

  if (withSorting) {
    searchQueries.sort_type = nullInString(searchParams.get('sort_type'));
    searchQueries.sort_desc = stringToBoolean(searchParams.get('sort_desc'));
  }

  const [search, setSearch] = useState(searchQueries.search_val || '');
  const [page, setPage] = useState(+searchQueries.page || 1);

  const [sortType, setSortType] = useState(withSorting && (searchQueries.sort_type ? sortTypes.find((item) => item?.key === searchQueries.sort_type) : sortTypes[0]));
  const [sortDesc, setSortDesc] = useState(withSorting && (typeof searchQueries.sort_desc === 'boolean' ? searchQueries.sort_desc : true));

  const [timer, setTimer] = useState(null);
  const [pagesCount, setPagesCount] = useState(0);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletableItem, setDeletableItem] = useState({});

  const [checkboxVal, setCheckboxVal] = useState(Boolean(checkBoxInfo?.defaultVal));

  const request = ({
    argsPage = page,
    argsSearch,
    argsSortDesc,
    argsSortType,
    ...rest
  }) => {
    requestData({
      limit: limitOnEachPage,
      offset: (argsPage - 1) * limitOnEachPage || 0,
      'page-number': argsPage - 1 || page - 1,
      'search-val': argsSearch === undefined || argsSearch === null ? search : argsSearch,
      'sort-desc': typeof argsSortDesc === 'boolean' ? argsSortDesc : sortDesc,
      'sort-field': argsSortType ? argsSortType.key : sortType?.key,
      'filter-val': {
        'val-project': null,
        'val-user': null,
      },
      ...rest,
    });
  };

  const changeSortType = (val, withNavigating = true) => {
    if (val === sortType) {
      return;
    }
    setSortType(val);
    setPage(1);
    if (withNavigating) {
      navigateSearch(location.pathname, {
        ...searchQueries,
        sort_type: val.key,
        page: 1,
      });
      request({ argsSortType: val, argsPage: 1 });
    }
  };

  const changeAscDesc = (val, withNavigating = true) => {
    setSortDesc(val);
    setPage(1);
    if (withNavigating) {
      navigateSearch(location.pathname, {
        ...searchQueries,
        sort_desc: val,
        page: 1,
      });
      request({ argsSortDesc: val, argsPage: 1 });
    }
  };

  const changeSearch = (val, withNavigating = true) => {
    setPage(1);
    if (withNavigating) {
      navigateSearch(location.pathname, {
        ...searchQueries,
        search_val: val,
        page: 1,
      });
      request({ argsSearch: val, argsPage: 1 });
    }
  };

  const changePage = (val, withNavigating = true) => {
    setPage(val);
    if (withNavigating) {
      navigateSearch(location.pathname, {
        ...searchQueries,
        page: val,
      });
      request({ argsPage: val });
    }
  };

  const onRefresh = () => {
    changeSortType(sortTypes[0], false);
    changeAscDesc(true, false);
    changeSearch('', false);
    setSearch('');
    setPage(1);
    navigateSearch(location.pathname, {});
    request({
      argsPage: 1,
      argsSearch: '',
      argsSortDesc: true,
      argsSortType: sortTypes[0],
    });
  };

  const handleSearch = (ev) => {
    setSearch(ev.target.value);
    clearTimeout(timer);

    if (!ev.target.value) {
      return changeSearch('');
    }

    const newTimer = setTimeout(() => {
      changeSearch(ev.target.value);
    }, 300);

    setTimer(newTimer);
  };

  useEffect(() => {
    request({});
  }, []);

  useEffect(() => {
    if (data?.total && data?.limit) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      setPagesCount(Math.ceil(data?.total / data?.limit));
    } else {
      setPagesCount(0);
    }
  }, [data]);

  const onDelete = () => {
    setShowDeleteModal(false);
    onDeleteClick({
      ...deletableItem,
      requestData: {
        limit: limitOnEachPage,
        page,
        offset: (page - 1) * limitOnEachPage,
        'search-val': '',
      },
    });
  };

  const onArchive = ({ id }) => {
    onArchiveClick({
      id,
      requestData: {
        limit: limitOnEachPage,
        page,
        offset: (page - 1) * limitOnEachPage,
        'search-val': '',
      },
    });
  };

  const onRecover = ({ id }) => {
    onRecoverClick({
      id,
      requestData: {
        limit: limitOnEachPage,
        page,
        offset: (page - 1) * limitOnEachPage,
        'search-val': '',
      },
    });
  };

  const showModal = (item) => {
    setShowDeleteModal(true);
    setDeletableItem(item);
  };

  const hideModal = () => {
    setShowDeleteModal(false);
    setTimeout(() => setDeletableItem({}), 300);
  };

  useEffect(() => {
    if (!checkBoxInfo?.withCheckbox) return;
    request({ [checkBoxInfo.fieldsName]: checkboxVal });
  }, [checkboxVal]);

  useEffect(() => {
    if (!checkBoxInfo?.withCheckbox) return;
    setCheckboxVal(Boolean(checkBoxInfo.defaultVal));
  }, [checkBoxInfo?.defaultVal]);

  return (
    <div className="sortable_list_wrapper">
      {withHeader && (
        <div className="header">
          <div className="row">
            {withRefresh && <MyButton onClick={onRefresh} className="refresh_button" iconClassName="refresh" iconUri="/images/refresh.png" loading={loading} loadingEqualsDisabled={false} />}
            {TitleButton && (!blockNotAdmin || isAdmin) && <TitleButton />}
            {checkBoxInfo?.withCheckbox && (
              <MyCheckbox
                value={checkboxVal}
                onChange={setCheckboxVal}
                label={checkBoxInfo.labelName}
                className="header_checkbox"
              />
            )}
          </div>
          <div className="row">
            {withSorting && (
              <>
                <SortTypeFilter data={sortTypes} value={sortType} onChange={changeSortType} />
                <DescFilter isDesc={sortDesc} onChange={changeAscDesc} />
              </>
            )}
            {withSearch && (
              <MyInput
                wrapperClassName="search_input_div"
                className="search_input"
                placeholder={t('search')}
                value={search}
                onChange={handleSearch}
              />
            )}
          </div>
        </div>
      )}
      {!data?.total && !loading ? <p className="nothing_found_text">{t('nothing_found')}</p> : null}
      {pagesCount ? (
        <div className="sortable_list_container">
          {HeaderRow && <HeaderRow />}
          {data?.items?.map((item, index) => renderItem(item, index, onItemEdit, showModal, t, loading, isAdmin, onItemClick, additionalCLick, onArchive, onRecover, renderItemPayload))}
        </div>
      ) : null}
      {data?.total > 10 ? (
        <div className="page_row">
          {new Array(pagesCount).fill('').map((_, index) => (
            <div onClick={() => changePage(index + 1)} className={`page_item ${index + 1 === page && 'page_item_active'}`} key={`page -${index}`}>{index + 1}</div>
          ))}
        </div>
      ) : null}
      <MyModal isOpen={showDeleteModal} closeFunction={hideModal}>
        <p className="delete_text">
          {t('sure_delete')}
          {labelToShowOnDelete}
          <span>{deletableItem && deletableItem[keyToShowOnDelete]}</span>
        </p>
        <div className="delete_row">
          <MyButton text={t('delete')} className="submit_delete" onClick={onDelete} />
          <MyButton text={t('cancel')} onClick={hideModal} />
        </div>
      </MyModal>
    </div>
  );
}

export default SortableList;
