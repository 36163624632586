import React from 'react';
import EditButton from '../../../../components/EditButton';
import DeleteButton from '../../../../components/DeleteButton';

function ProjectItem(item, index, onItemEdit, showDeleteModal, t, loading, isAdmin, _1, _2, onArchive, onRecover) {
  return item && (
    <div key={item.id} className="project_item">
      <div className="project_left">
        <img src="/images/projects.png" alt="img" className="project_img" />
        <div className="project_info_div">
          <p className="project_title">{item['short-name'] || item.title}</p>
          <p className="project_desc">{item.descr}</p>
        </div>
      </div>
      {!loading && isAdmin && (
        <div className="row">
          {item['flag-del'] ? (
            <>
              <EditButton
                text={t('recover')}
                onClick={() => onRecover(item)}
              />
              <DeleteButton onClick={() => showDeleteModal(item)} text={t('delete_full')} />
            </>
          ) : (
            <>
              <EditButton onClick={() => onItemEdit(item)} />
              <DeleteButton onClick={() => onArchive(item)} text={t('archive')} />
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default ProjectItem;
