import { ROLES } from '../constants';

export default [
  {
    title: 'documents',
    links: [
      {
        name: 'events',
        icon: '/images/events_icon.png',
      },
      {
        name: 'prescriptions',
        icon: '/images/prescriptions_icon.png',
      },
      {
        name: 'acts',
        icon: '/images/acts_icon.png',
      },
      {
        name: 'checkups',
        icon: '/images/work_accept_icon.png',
        withRightComponent: true,
      },
    ],
  },
  {
    title: 'construction_objects',
    links: [
      {
        name: 'partners',
        icon: '/images/partners_icon.png',
        hideFor: [ROLES.constructor, ROLES.client],
      },
      {
        name: 'projects',
        icon: '/images/projects_icon.png',
        hideFor: [ROLES.constructor, ROLES.client],
      },
      {
        name: 'objects',
        icon: '/images/objects_icon.png',
        hideFor: [ROLES.constructor, ROLES.client],
      },
      {
        name: 'allocated_users',
        icon: '/images/alloc_users_icon.png',
        hideFor: [ROLES.constructor, ROLES.client],

      },
      {
        name: 'drawings',
        icon: '/images/drawing_icon.png',
      },
    ],
  },
  {
    title: 'administrating',
    links: [
      {
        name: 'company_letterheads',
        icon: '/images/company_letterheads_icon.png',
        adminProtected: true,
      },
      {
        name: 'users',
        icon: '/images/users_icon.png',
        adminProtected: true,
      },
    ],
  },
  {
    title: 'additional',
    links: [
      {
        name: 'instructions',
        icon: '/images/instructions_icon.png',
      },
      {
        name: 'checklists',
        icon: '/images/checklists_icon.png',
        hideFor: [ROLES.constructor, ROLES.client],
      },
      {
        name: 'next_cloud',
        icon: '/images/next_cloud_icon.png',
      },
      {
        name: 'logout',
        icon: '/images/logout_icon.png',
      },
    ],
  },
];

export const rootNavLinkList = [
  {
    title: 'root',
    links: [
      {
        name: 'users',
        icon: '/images/users_icon.png',
      },
      {
        name: 'configuration',
        icon: '/images/config_icon.png',
      },
      {
        name: 'clients',
        icon: '/images/config_icon.png',
      },
      {
        name: 'logout',
        icon: '/images/logout_icon.png',
      },
    ],
  },
];
