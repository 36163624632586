import React from 'react';
import './css/index.scss';
import MyButton from '../../templates/MyButton';
import useTranslation from '../../translations';

function DeleteButton({
  onClick, text, loading, disabled,
}) {
  const { t } = useTranslation();
  return (
    <MyButton
      disabled={disabled}
      onClick={onClick}
      text={text || t('delete')}
      className="delete_button"
      textClassName="delete_button_text"
      loading={loading}
    />
  );
}

export default DeleteButton;
