import React, { useEffect, useState } from 'react';
import './css/index.scss';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/ru';
import { useDispatch } from 'react-redux';
import useTranslation from '../../../translations';
import AppointmentWrapper from './components/AppointmentWrapper';
import Api from '../../../Api';
import MyLoading from '../../../templates/MyLoading';
import { COLORS } from '../../../helpers/constants';
import MyButton from '../../../templates/MyButton';
import { setSingleAppointment } from '../../../store/actions/appointment';
import LocalStorageServices from '../../../helpers/LocalStorageServices';
import getAppointmentDateInfo from '../../../helpers/getAppointmentDateInfo';
import YourAppointmentItem from './components/YourAppointmentItem';

function Calendar() {
  // eslint-disable-next-line prefer-const
  let { lang, client_id: clientId, object_id: objectId } = useParams();
  if (!['en', 'ru'].includes(lang)) lang = 'ru';
  moment.locale(lang);

  const navigate = useNavigate();
  const { t } = useTranslation(lang);
  const dispatch = useDispatch();

  const [existingAppointments, setExistingAppointments] = useState();
  const [data, setData] = useState();
  const [gettingLoading, setGettingLoading] = useState(false);

  const onSlotClick = ({
    time,
    dateInfo,
    date,
    engineer,
  }) => {
    delete engineer.slots;

    const payload = {
      date,
      time,
      engineer,
      dateInfo,
      project_title: data?.project_title,
      object_title: data?.object_title,
      object_address: data?.object_address,
      object_id: objectId,
      client_id: clientId,
    };

    dispatch(setSingleAppointment(payload));

    const path = `${location.pathname}/edit`.replaceAll('//', '/');

    navigate(path);
  };

  const onAppointmentItemClick = (id) => {
    const path = `${location.pathname}/view?appointment_my_id=${id}`.replaceAll('//', '/');
    navigate(path);
  };

  const getDataFromStorage = (refreshSchedule) => {
    const existingList = LocalStorageServices.getAppointmentsList(objectId);
    setExistingAppointments(existingList);

    if (refreshSchedule) {
      getAppointmentsSchedule().then();
    }
  };

  const getAppointmentsSchedule = async () => {
    if (objectId) {
      try {
        getDataFromStorage();

        setGettingLoading(true);
        const { data: res } = await Api.getAppointment({ object_id: objectId });
        setData(res);
      } catch (e) {
        console.warn(e, 'while getting appointment');
      } finally {
        setGettingLoading(false);
      }
    }
  };

  useEffect(() => {
    getDataFromStorage();
    getAppointmentsSchedule().then();
  }, [objectId]);

  return (
    <AppointmentWrapper>
      {existingAppointments?.length ? (
        <div>
          <p className="page_title">{t('your_appointment_record')}</p>
          {existingAppointments.map((item, index) => (
            <YourAppointmentItem
              item={item}
              index={index}
              key={index}
              getRefreshedData={getDataFromStorage}
              onItemClick={onAppointmentItemClick}
            />
          ))}
        </div>
      ) : null}

      <p className="page_title">{t('appointment_record')}</p>

      {gettingLoading && <MyLoading color={COLORS.main} />}
      <div className="simple_block">
        <p className="small_title">{t('organization')}</p>
        <p className="simple_text">{data?.project_title}</p>
      </div>
      <div className="simple_block">
        <p className="small_title">{t('object')}</p>
        <p className="simple_text">{`${data?.object_address} (${data?.object_title})`}</p>
      </div>

      <div className="info_block">
        <img className="info_block_img" src="/images/appointment_info_icon.png" alt="U" />
        <p className="info_block_text">{t('appointment_info')}</p>
      </div>

      <div className="schedule_block">
        {!data?.dates?.length ? <p className="error_text">{t('no_available_engineers')}</p> : (
          data.dates.map((dateItem, dateIndex) => {
            const date = moment(dateItem.date, 'DD.MM.YYYY');
            const isToday = moment(dateItem.date, 'DD.MM.YYYY')
              .isSame(moment(), 'day');
            const dateInfo = getAppointmentDateInfo(date, dateIndex, t);

            return (
              <div key={dateIndex} className="schedule_block">
                <p className="small_title">{dateInfo}</p>

                {!dateItem.allocated_users?.length
                  ? <p className="error_text">{t('no_available_engineers_this_day')}</p> : (
                    dateItem.allocated_users.map((engineerItem, engineerIndex) => (
                      <div className="engineer_block" key={engineerIndex}>
                        <p className="simple_text">{engineerItem.fio}</p>

                        {!Object.keys(engineerItem.slots).length
                          ? <p className="error_text margin_top">{t('engineer_not_this_day')}</p> : (
                            <div className="slots_row">
                              {Object.keys(engineerItem.slots)
                                .map((slotKey, slotIndex) => (
                                  <MyButton
                                    text={slotKey}
                                    disabled={(isToday && moment()
                                      .isAfter(moment(slotKey, 'HH:mm'))) || engineerItem.slots[slotKey] !== true}
                                    className="slots_item"
                                    onClick={() => onSlotClick({
                                      time: slotKey,
                                      dateInfo,
                                      date: dateItem.date,
                                      engineer: engineerItem,
                                    })}
                                    key={slotIndex}
                                  />
                                ))}
                            </div>
                          )}
                      </div>
                    ))
                  )}
              </div>
            );
          })
        )}
      </div>
    </AppointmentWrapper>
  );
}

export default Calendar;
