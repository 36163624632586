import React, { useEffect, useMemo, useState } from 'react';
import './css/index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import SinglePageHeader from '../../../components/SinglePageHeader';
import useTranslation from '../../../translations';
import MyLoading from '../../../templates/MyLoading';
import { CHECKUP_STATUSES, COLORS, ROLES } from '../../../helpers/constants';
import Api from '../../../Api';
import HeaderRightComponent from './components/HeaderRightComponent';
import { getProjectsRequest } from '../../../store/actions/projects';
import requestAllData from '../../../helpers/requestAllData';
import useInputState from '../../../helpers/hooks/useInputState';
import MySelect from '../../../templates/MySelect';
import { getObjectsRequest } from '../../../store/actions/objects';
import MyInput from '../../../templates/MyInput';
import ChecklistMarks from './components/ChecklistMarks';
import { getChecklistTools } from '../../../store/actions/checklists';
import ChecklistPrescription from './components/ChecklistPrescription';
import {
  cleanSinglePrescription, getSinglePrescriptionRequest,
  savePrescriptionRequest,
} from '../../../store/actions/prescriptions';
import MyButton from '../../../templates/MyButton';
import sleep from '../../../helpers/sleep';
import DeleteButton from '../../../components/DeleteButton';

function SingleCheckup() {
  const dispatch = useDispatch();
  const { t, lang } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const checkupId = searchParams.get('checkup_id');

  const profile = useSelector((store) => store.account.profile);
  const role = useSelector((store) => store.account.role);

  const projects = useSelector((store) => store.projects.projects);
  const toolsObj = useSelector((store) => store.checklists.toolsObj);
  const objects = useSelector((store) => store.objects.objects);
  const singlePrescription = useSelector((store) => store.prescriptions.singlePrescription);
  const gettingSinglePrescriptionLoading = useSelector((store) => store.prescriptions.gettingSinglePrescriptionLoading);
  const savingPrescriptionLoading = useSelector((store) => store.prescriptions.savingPrescriptionLoading);
  const creatingPrescriptionLoading = useSelector((store) => store.prescriptions.creatingPrescriptionLoading);

  const [project, setProject, projectError, setProjectError] = useInputState();
  const [object, setObject, objectError, setObjectError] = useInputState();

  const [viols, setViols, violsError, setViolsError] = useInputState([]);

  const [podryadchik, setPodryadchik, podryadchikError, setPodryadchikError] = useInputState();

  const [isDisabledObjectEditing, setIsDisabledObjectEditing] = useState(false);
  const [filteredObjects, setFilteredObjects] = useState();

  const [incomingControls, setIncomingControls] = useState([]);
  const [operationalControls, setOperationalControls] = useState([]);
  const [outputControls, setOutputControls] = useState([]);

  const [gettingLoading, setGettingLoading] = useState(true);
  const [singleCheckup, setSingleCheckup] = useState();
  const [isSynchronizing, setIsSynchronizing] = useState(false);
  const [goingToPrescription, setGoingToPrescription] = useState(false);
  const [finishingCheckup, setFinishingCheckup] = useState(false);
  const [deletingLoading, setDeletingLoading] = useState(false);

  const getSingleCheckup = async () => {
    try {
      const { data } = await Api.getCheckup(checkupId);
      setSingleCheckup(data);
      if (data.predpisan_id) {
        setIsDisabledObjectEditing(true);
        dispatch(getSinglePrescriptionRequest({ 'filter-val': { id: data.predpisan_id } }));
      }
      setIncomingControls(data?.is_incoming);
      setOperationalControls(data?.is_operational);
      setOutputControls(data?.is_output);
      setPodryadchik(data?.fio);
      if (data?.project_id) {
        handleProject(data.project_id, true, true, true);
      }
      if (data?.object_id) {
        setObject(data.object_id);
      }

      await sleep(300);
      setIsSynchronizing(true);
    } catch (e) {
      console.warn(e, 'while getting single checkup');
    } finally {
      setGettingLoading(false);
    }
  };

  const handleProject = (val, setInstantly, cleanObject = true, setFiltered) => {
    setProject(val, setInstantly);
    if (cleanObject) {
      handleObject(null, true);
      setFilteredObjects([]);
    }
    if (val && setFiltered) {
      setFilteredObjects(objects?.items?.filter((item) => item.project_id === +val));
    }
  };

  const handleObject = (val, setInstantly) => {
    setObject(val, setInstantly);
    editCheckup({ build_objs_id: val }).then();
  };

  const editCheckup = async (obj) => {
    try {
      if (!isSynchronizing) return;
      const { data } = await Api.editCheckup({ checkup_id: +checkupId, ...obj });
    } catch (e) {
      console.warn(e, 'while editing checkup');
    }
  };

  const saveViols = () => {
    if (!viols?.length || !singlePrescription?.id) return;

    const files = [];

    const formattedViols = viols.filter(({ text }) => text.trim()).map((item, index) => {
      if (item['flag-del'] && !item['viol-id']) {
        return;
      }

      const uniqueId = uuidv4();
      if (item.file) {
        files.push({ uid: uniqueId, file: item.file });
      }

      const data = {
        'flag-del': false,
        ...item,
        title: item.text,
        'sort-number': index,
        'temp-uid': '',
        'picture-file': item['picture-file'] || '',
        drawing: {
          draw_id: null, point_top: null, point_left: null, point_direction: null,
        },
      };

      if (item.drawing) {
        data.drawing = item.drawing;
      }

      if (item.file?.name) {
        data['picture-file'] = item.file.name;
        data['temp-uid'] = uniqueId;
      }

      delete data.file;
      delete data.udate;
      delete data.cdate;

      return data;
    }).filter((item) => item);

    const payload = {
      id: singlePrescription.id,
      'next-id': null, //----------
      title: '', //----------
      'number-n': 0, //----------
      descr: '', //---------
      'scan-file': null, //---------
      'date-out': moment().format('yyyy-MM-DD'),
      'date-exp': moment().format('yyyy-MM-DD'),
      'number-out': singlePrescription['number-out'],
      status: 'draft',
      priority: 'low',
      'engineer-user-alc-id': singlePrescription['engineer-user-alc-id'],
      'agreement-user-alc-id': null,
      'signatory-user-alc-id': null,
      'build-obj-id': singlePrescription['build-obj-id'],
      'build-obj-title': objects?.items?.find((item) => item.id === singlePrescription['build-obj-id'])?.title,
      'project-title': projects?.items?.find((item) => item.id === singlePrescription['project-id'])?.title,
      'project-id': singlePrescription['project-id'],
      viols: formattedViols,
      items: [],
    };

    console.log(payload);

    const formData = new FormData();
    formData.append('body', JSON.stringify(payload));

    files.forEach(({ uid, file }) => {
      formData.append(uid, file);
    });

    dispatch(savePrescriptionRequest(formData));
  };

  const finishCheckup = () => {
    if (!viols?.length || !singlePrescription?.id) {
      finishCheckupWithoutSaving();
      return;
    }

    saveViols();
    setFinishingCheckup(true);
  };

  const finishCheckupWithoutSaving = () => {
    navigate('/checkups');
  };

  const deleteCheckup = async () => {
    try {
      setDeletingLoading(true);
      const payload = {
        checkup_id: checkupId,
        status: CHECKUP_STATUSES.deleted,
      };

      const { data } = await Api.editCheckup(payload);

      if (data?.message === 'Success') {
        navigate('..');
      }
    } catch (e) {
      console.warn(e, 'while deleting checkup');
    } finally {
      setDeletingLoading(false);
    }
  };

  const goToPrescription = () => {
    saveViols();
    setGoingToPrescription(true);
  };

  const toolsToDraw = useMemo(() => {
    if (Object.keys(toolsObj).length && singleCheckup?.tools_array) {
      const toolsIds = JSON.parse(singleCheckup.tools_array);
      return toolsIds.reduce((prev, curr) => [...prev, toolsObj[curr]], []);
    }

    return [];
  }, [toolsObj, singleCheckup?.tools_array]);

  useEffect(() => {
    if (checkupId) {
      getSingleCheckup().then();
      dispatch(cleanSinglePrescription());
      dispatch(getProjectsRequest(requestAllData));
      dispatch(getObjectsRequest(requestAllData));
      if (!Object.keys(toolsObj).length) {
        dispatch(getChecklistTools());
      }
    }
  }, [checkupId]);

  useEffect(() => {
    if (objects?.items?.length && project) {
      handleProject(project, true, false, true);
    }
  }, [objects, project]);

  useEffect(() => {
    if (!isSynchronizing) return;

    const timerId = setTimeout(() => {
      editCheckup({ fio: podryadchik }).then();
    }, 300);

    return () => clearTimeout(timerId);
  }, [podryadchik]);

  useEffect(() => {
    if (!gettingSinglePrescriptionLoading && singlePrescription?.id && singlePrescription?.viols?.length) {
      const newViols = singlePrescription.viols.map((item) => ({ ...item, text: item.title }));
      setViols(newViols, true);
    } else {
      setViols([], true);
    }
  }, [gettingSinglePrescriptionLoading, creatingPrescriptionLoading]);

  useEffect(() => {
    if (!savingPrescriptionLoading && singlePrescription?.id) {
      if (finishingCheckup) {
        navigate('/checkups');
      } else if (goingToPrescription) {
        navigate(`/prescriptions/edit?prescription_id=${singlePrescription.id}`);
      }
    }
  }, [savingPrescriptionLoading]);

  return (
    <div className="single_checkup_wrapper">
      <SinglePageHeader text={t('checkup')} rightComponent={role === ROLES.stroymanager && <HeaderRightComponent t={t} />} functionAtGoingBack={saveViols} />
      {gettingLoading ? <MyLoading color={COLORS.main} />
        : ![ROLES.stroymanager, ROLES.chief].includes(role) ? <p className="no_checkup">{t('checkup_only_engineer')}</p>
          : (role !== ROLES.chief && singleCheckup?.engineer_fio !== profile?.fio) ? <p className="no_checkup">{t('checkup_wrong_engineer')}</p>
            : [CHECKUP_STATUSES.requested, CHECKUP_STATUSES.deleted].includes(singleCheckup?.status) ? <p className="no_checkup">{t('checkup_wrong_status')}</p>
              : !singleCheckup ? (
                <p className="no_checkup">
                  {t('no_checkup')}
                  {checkupId}
                </p>
              ) : (
                <div>
                  <div className="top_part">
                    <div className="selections_part">
                      <div className="selection_part_item">
                        <p className="selection_title">ID:</p>
                        <p className="selection_value">{checkupId}</p>
                      </div>
                      <div className="selection_part_item">
                        <p className="selection_title">
                          {t('engineer')}
                          :
                        </p>
                        <p className="selection_value">{singleCheckup?.engineer_fio || profile?.fio}</p>
                      </div>
                      <div className="selection_part_item">
                        <p className="selection_title">
                          {t('template')}
                          :
                        </p>
                        <p className="selection_value">{singleCheckup?.template_title}</p>
                      </div>
                      <div className="selection_part_item">
                        <p className="selection_title">
                          {t('project')}
                          :
                        </p>
                        <MySelect
                          readOnly={isDisabledObjectEditing || role === ROLES.chief}
                          wrapperClassName="selection_value"
                          data={projects?.items}
                          showKey="title"
                          buttonText={t('choose_project')}
                          value={project}
                          onChange={handleProject}
                          onlyDropDown
                          t={t}
                        />

                      </div>
                      <div className="selection_part_item">
                        <p className="selection_title">
                          {t('object')}
                          :
                        </p>
                        <MySelect
                          readOnly={isDisabledObjectEditing || role === ROLES.chief}
                          wrapperClassName="selection_value"
                          data={filteredObjects}
                          showKey="title"
                          buttonText={t('choose_object')}
                          value={object}
                          onChange={handleObject}
                          onlyDropDown
                          t={t}
                        />

                      </div>
                      <div className="selection_part_item">
                        <p className="selection_title">
                          {t('podryadchik')}
                          :
                        </p>
                        <MyInput
                          placeholder={t('checkup_podr_placeholder')}
                          value={podryadchik}
                          onChange={setPodryadchik}
                          wrapperClassName="selection_value"
                          readOnly={role === ROLES.chief}
                        />
                      </div>
                    </div>
                    <div className="tools_part">
                      <p className="tools_title">
                        {t('tools')}
                        {' '}
                        (
                        {toolsToDraw?.length}
                        )
                      </p>
                      <ul className="tools_list">
                        {toolsToDraw?.map((item) => (
                          <li key={item.id} className="tools_item">{item[`name_${lang}`]}</li>
                        ))}
                      </ul>
                      {singleCheckup?.requirements && (
                      <>
                        <p className="tools_title">{t('technical_requirements')}</p>
                        <p className="tools_text">{singleCheckup?.requirements}</p>
                      </>
                      )}
                      {singleCheckup?.comment && (
                      <>
                        <p className="tools_title">{t('checkup_note')}</p>
                        <p className="tools_text">{singleCheckup.comment}</p>
                      </>
                      )}

                    </div>

                  </div>
                  <div className="checklist_part">
                    <p className="checklist_title">{t('checklist')}</p>
                    <ChecklistMarks
                      setData={setIncomingControls}
                      data={incomingControls}
                      title={t('incoming_control')}
                      subTitle={singleCheckup?.incoming_docs}
                      checkupId={checkupId}
                      disabled={role === ROLES.chief}
                    />
                    <ChecklistMarks
                      setData={setOperationalControls}
                      data={operationalControls}
                      title={t('operational_control')}
                      subTitle={singleCheckup?.operational_docs}
                      checkupId={checkupId}
                      disabled={role === ROLES.chief}
                    />
                    <ChecklistMarks
                      setData={setOutputControls}
                      data={outputControls}
                      title={t('output_control')}
                      subTitle={singleCheckup?.output_docs}
                      checkupId={checkupId}
                      disabled={role === ROLES.chief}
                    />
                  </div>
                  {role === ROLES.stroymanager && (
                    <>
                      <ChecklistPrescription
                        project={project}
                        object={object}
                        setIsDisabledObjectEditing={setIsDisabledObjectEditing}
                        viols={viols}
                        setViols={setViols}
                        goToPrescription={goToPrescription}
                        editCheckup={editCheckup}
                        goingToPrescription={goingToPrescription}
                        buildObj={singleCheckup?.object_id
                          ? objects?.items?.find((item) => item.id === singleCheckup.object_id)
                          : objects?.items?.find((item) => item.id === object)}
                      />

                      <p className="comment">{t('checkup_comment')}</p>
                      <div className="single_checkup_buttons_row">
                        <MyButton
                          loading={finishingCheckup}
                          className="finish_checkup"
                          text={t('finish_checkup')}
                          onClick={finishCheckup}
                        />
                        {singlePrescription?.id && (
                          <MyButton
                            loading={finishingCheckup}
                            className="finish_checkup"
                            text={t('finish_checkup_no_saving')}
                            onClick={finishCheckupWithoutSaving}
                          />
                        )}
                        <DeleteButton
                          text={t('delete')}
                          onClick={deleteCheckup}
                          loading={deletingLoading}
                          disabled={finishingCheckup}
                        />
                      </div>
                    </>
                  )}
                </div>
              )}
    </div>
  );
}

export default SingleCheckup;
