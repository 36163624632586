import React from 'react';
import './css/index.scss';
import MyButton from '../../templates/MyButton';
import useTranslation from '../../translations';

function EditButton({ onClick, text }) {
  const { t } = useTranslation();
  return (
    <MyButton
      onClick={onClick}
      text={text || t('view_and_edit')}
      className="edit_button"
      textClassName="edit_button_text"
    />
  );
}

export default EditButton;
