import React, { useEffect, useMemo, useState } from 'react';
import './css/index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import useTranslation from '../../../translations';
import SinglePageHeader from '../../../components/SinglePageHeader';
import useInputState from '../../../helpers/hooks/useInputState';
import MyInput from '../../../templates/MyInput';
import MyButton from '../../../templates/MyButton';
import CancelButton from '../../../components/CancelButton';
import {
  getAllocsRequest, saveAllocRequest,
  setSingleAlloc,
} from '../../../store/actions/allocs';
import MySelect from '../../../templates/MySelect';
import SelectedCompanyItem from './components/SelectedCompanyItem';
import { getCompaniesRequest } from '../../../store/actions/companies';
import { getProjectsRequest } from '../../../store/actions/projects';
import SelectedProjectItem from './components/SelectedProjectItem';
import { getUsersRequest } from '../../../store/actions/users';
import SelectedUserItem from './components/SelectedUserItem';
import requestAllData from '../../../helpers/requestAllData';

function SingleAlloc() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const allocId = searchParams.get('alloc_id');

  const allocs = useSelector((store) => store.allocs.allocs);
  const singleAlloc = useSelector((store) => store.allocs.singleAlloc);
  const saveLoading = useSelector((store) => store.allocs.saveLoading);
  const saveError = useSelector((store) => store.allocs.saveError);

  const companies = useSelector((store) => store.companies.companies);
  const projects = useSelector((store) => store.projects.projects);
  const users = useSelector((store) => store.users.users);

  const [user, setUser, userError, setUserError] = useInputState();
  const [position, setPosition, positionError, setPositionError] = useInputState();
  const [number, setNumber, numberError, setNumberError] = useInputState();
  const [date, setDate, dateError, setDateError] = useInputState();
  const [company, setCompany, companyError, setCompanyError] = useInputState();
  const [project, setProject, projectError, setProjectError] = useInputState();

  const activeUser = useMemo(() => {
    if (users?.items?.length) {
      return users.items.find((u) => u.id === user);
    }
  }, [user]);

  const [toSave, setToSave] = useState(false);

  useEffect(() => {
    if (!users?.items) {
      dispatch(getUsersRequest(requestAllData));
    }
    if (!companies?.items) {
      dispatch(getCompaniesRequest(requestAllData));
    }
    if (!projects?.items) {
      dispatch(getProjectsRequest(requestAllData));
    }
    if (!allocId) {
      return;
    }
    if (!allocs?.items) {
      dispatch(getAllocsRequest(requestAllData));
    }
  }, []);

  useEffect(() => {
    if (allocId && allocs?.items) {
      dispatch(setSingleAlloc(allocId));
    }
  }, [allocs]);

  useEffect(() => {
    if (allocId && singleAlloc?.id) {
      setUser(singleAlloc['user-id']);
      setPosition(singleAlloc?.role);
      setNumber(singleAlloc['prikaz-number']);
      setDate(moment(singleAlloc['prikaz-date']).format('yyyy-MM-DD'));
      setCompany(singleAlloc['build-comp-id']);
      setProject(singleAlloc['project-id']);
    }
  }, [singleAlloc]);

  useEffect(() => {
    if (toSave && !saveLoading) {
      if (!saveError) {
        goBack();
      } else {
        setToSave(false);
      }
    }
  }, [saveLoading]);

  const goBack = () => {
    navigate(-1, { replace: true });
  };

  const onSave = () => {
    let flag = false;
    if (!user) {
      setUserError(t('user') + t('is_required_m_r'));
      flag = true;
    }
    if (!position) {
      setPositionError(t('position_alloc') + t('is_required_j_r'));
      flag = true;
    }
    if (!number) {
      setNumberError(t('number') + t('is_required_m_r'));
      flag = true;
    }
    if (!date) {
      setDateError(t('date') + t('is_required_j_r'));
      flag = true;
    }
    if (activeUser?.is_constructor && !company) {
      setCompanyError(t('company') + t('is_required_j_r'));
      flag = true;
    }
    if (!project) {
      setProjectError(t('project') + t('is_required_m_r'));
      flag = true;
    }

    if (flag) {
      return;
    }

    const currentUserUid = users?.items?.find((item) => item.id === user);

    const data = {
      'build-comp-id': company,
      'project-id': project,
      'prikaz-date': moment(date).format('YYYY-MM-DD'),
      'prikaz-number': number,
      'user-id': currentUserUid?.uid,
      'type-id': singleAlloc?.['type-id'] || currentUserUid['user-type-id'],
      role: position,
    };

    if (allocId) {
      data.id = +allocId;
    }

    if (allocId) {
      dispatch(saveAllocRequest({ data }));
    } else {
      dispatch(saveAllocRequest({ data, save: true }));
    }
    setToSave(true);
  };

  return (
    <div className="single_alloc_wrapper">
      <SinglePageHeader text={allocId ? t('edit_alloc') : t('add_alloc')} />
      {allocs?.items && allocId && !singleAlloc?.id ? (
        <p className="no_alloc">
          {t('no_alloc')}
          {allocId}
        </p>
      ) : (
        <>
          <MySelect
            wrapperClassName="single_alloc_input"
            label={t('user')}
            renderItem={SelectedUserItem}
            data={users?.items}
            showKey="uid"
            buttonText={t('choose_user')}
            value={user}
            onChange={setUser}
            error={userError}
            t={t}
          />
          <MyInput
            wrapperClassName="single_alloc_input"
            label={t('user_position_via_project')}
            value={position}
            onChange={setPosition}
            error={positionError}
          />
          <MyInput
            wrapperClassName="single_alloc_input"
            label={t('number_alloc')}
            value={number}
            onChange={setNumber}
            error={numberError}
          />
          {/* <MyInput wrapperClassName="single_alloc_input" label={t('date_alloc')} min={moment().format('YYYY-MM-DD')} value={date} onChange={setDate} error={dateError} type="date" /> */}
          <MyInput
            wrapperClassName="single_alloc_input"
            label={t('date_alloc')}
            value={date}
            onChange={setDate}
            error={dateError}
            type="date"
          />
          <MySelect
            wrapperClassName="single_alloc_input"
            label={t('project')}
            renderItem={SelectedProjectItem}
            data={projects?.items}
            showKey="title"
            buttonText={t('choose_project')}
            value={project}
            onChange={setProject}
            error={projectError}
            t={t}
          />
          {activeUser?.is_constructor ? (
            <MySelect
              wrapperClassName="single_alloc_input"
              label={t('company_alloc')}
              renderItem={SelectedCompanyItem}
              data={companies?.items}
              showKey="title"
              buttonText={t('choose_company')}
              value={company}
              onChange={setCompany}
              error={companyError}
              t={t}
            />
          ) : null}
          <div className="buttons_row">
            <MyButton
              loading={saveLoading}
              onClick={onSave}
              text={allocId ? t('edit_alloc_button') : t('add_alloc_button')}
            />
            <CancelButton loading={saveLoading} />
          </div>
        </>
      )}
    </div>
  );
}

export default SingleAlloc;
