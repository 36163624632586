import sleep from './sleep';

export default async ({
  request,
  setLoading,
  warnErrorText,
  payload,
}) => {
  try {
    if (setLoading) {
      setLoading(true);
    }

    const { data } = await request(payload);
    return data;
  } catch (e) {
    console.warn(e?.response?.data, warnErrorText);
    return e?.response?.data;
  } finally {
    if (setLoading) {
      setLoading(false);
    }
  }
};
