import React from 'react';
import './css/index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useSortTypes from '../../DocumentsPages/Events/components/sortTypes';
import {
  archiveProjectRequest,
  deleteProjectRequest,
  getProjectsRequest, recoverProjectRequest,
} from '../../../store/actions/projects';
import SortableList from '../../../templates/SortableList';
import ProjectItem from './components/ProjectItem';
import AddButton from '../../../components/AddButton';
import useTranslation from '../../../translations';
import RequestAllData from '../../../helpers/requestAllData';

function Projects() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const projects = useSelector((store) => store.projects.projects);
  const projectsLoading = useSelector((store) => store.projects.projectsLoading);
  const sortTypes = useSortTypes();

  const getProjects = (data) => {
    dispatch(getProjectsRequest(data));
  };

  const onAddClick = () => {
    navigate('/projects/add');
  };

  const onItemRecover = ({ id }) => {
    dispatch(recoverProjectRequest({
      id,
      requestData: RequestAllData,
    }));
  };

  const onItemArchive = ({ id, requestData }) => {
    dispatch(archiveProjectRequest({ id, requestData }));
  };

  const onEditClick = (item) => {
    navigate(`/projects/edit?project_id=${item.id}`);
  };

  const onDeleteClick = ({ id, requestData }) => {
    dispatch(deleteProjectRequest({ id, requestData }));
  };

  const addButton = () => (
    AddButton({ text: t('add_project_btn'), onClick: onAddClick })
  );

  return (
    <div className="projects_wrapper">
      <SortableList
        data={projects}
        loading={projectsLoading}
        requestData={getProjects}
        sortTypes={sortTypes}
        renderItem={ProjectItem}
        onArchiveClick={onItemArchive}
        onRecoverClick={onItemRecover}
        onItemEdit={onEditClick}
        onDeleteClick={onDeleteClick}
        TitleButton={addButton}
        limitOnEachPage={10}
        keyToShowOnDelete="title"
      />
    </div>
  );
}

export default Projects;
