import React from 'react';
import './css/index.scss';
import MyLoading from '../MyLoading';

function MyButton({
  loading,
  onClick,
  text,
  iconUri,
  className = '',
  textClassName = '',
  iconClassName = '',
  loadingEqualsDisabled = true,
  disabled,
}) {
  return (
    <div onClick={!loading && !disabled ? onClick : undefined} className={`${className} ${((loading && loadingEqualsDisabled) || disabled) && 'disabled_button'} button_div`}>
      <MyLoading className="loading" visible={!!loading} />

      {iconUri && <img src={iconUri} alt="" className={`${iconClassName} ${loading && 'disabled'} button_icon`} />}
      {text && <p className={`${textClassName} ${loading && 'disabled'} button_text`}>{text}</p>}
    </div>
  );
}

export default MyButton;
