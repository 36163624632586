import React from 'react';
import './css/index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useSortTypes from '../../DocumentsPages/Events/components/sortTypes';
import {
  archiveObjectRequest,
  deleteObjectRequest,
  getObjectsRequest, recoverObjectRequest,
} from '../../../store/actions/objects';
import SortableList from '../../../templates/SortableList';
import ObjectItem from './components/ObjectItem';
import AddButton from '../../../components/AddButton';
import useTranslation from '../../../translations';
import RequestAllData from '../../../helpers/requestAllData';

function Objects() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const objects = useSelector((store) => store.objects.objects);
  const objectsLoading = useSelector((store) => store.objects.objectsLoading);
  const sortTypes = useSortTypes();

  const getObjects = (data) => {
    dispatch(getObjectsRequest(data));
  };

  const onAddClick = () => {
    navigate('/objects/add');
  };

  const onItemRecover = ({ id }) => {
    dispatch(recoverObjectRequest({
      id,
      requestData: RequestAllData,
    }));
  };

  const onItemArchive = ({ id, requestData }) => {
    dispatch(archiveObjectRequest({ id, requestData }));
  };

  const onEditClick = (item) => {
    navigate(`/objects/edit?object_id=${item.id}`);
  };

  const onDeleteClick = ({ id, requestData }) => {
    dispatch(deleteObjectRequest({ id, requestData }));
  };

  const addButton = () => (
    AddButton({ text: t('add_object_btn'), onClick: onAddClick })
  );

  return (
    <div className="objects_wrapper">
      <SortableList
        data={objects}
        loading={objectsLoading}
        requestData={getObjects}
        sortTypes={sortTypes}
        renderItem={ObjectItem}
        onArchiveClick={onItemArchive}
        onRecoverClick={onItemRecover}
        onItemEdit={onEditClick}
        onDeleteClick={onDeleteClick}
        TitleButton={addButton}
        limitOnEachPage={10}
        keyToShowOnDelete="title"
      />
    </div>
  );
}

export default Objects;
