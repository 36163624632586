import React from 'react';
import EditButton from '../../../../components/EditButton';
import DeleteButton from '../../../../components/DeleteButton';

function ObjectItem(item, index, onItemEdit, showDeleteModal, t, loading, isAdmin, _1, _2, onArchive, onRecover) {
  return item && (
    <div key={item.id} className="object_item">
      <div className="object_left">
        <img src="/images/objects.png" alt="img" className="object_img" />
        <div className="object_info_div">
          <p className="object_title">{item['short-name'] || item.title}</p>
          <p className="object_desc">{item.address}</p>
          <p className="object_info">
            {item && item['comp-title']}
            {' '}
            (
            {item && item['comp-descr']}
            )
          </p>
          <p className="object_info">
            {item && item['project-title']}
            {' '}
            (
            {item && item['project-descr']}
            )
          </p>
        </div>
      </div>
      {!loading && isAdmin && (
        <div className="row">
          {item['flag-del'] ? (
            <>
              <EditButton
                text={t('recover')}
                onClick={() => onRecover(item)}
              />
              <DeleteButton onClick={() => showDeleteModal(item)} text={t('delete_full')} />
            </>
          ) : (
            <>
              <EditButton onClick={() => onItemEdit(item)} />
              <DeleteButton onClick={() => onArchive(item)} text={t('archive')} />
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default ObjectItem;
