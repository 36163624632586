import React, { useEffect, useState } from 'react';
import './css/index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import useTranslation from '../../../translations';
import SinglePageHeader from '../../../components/SinglePageHeader';
import useInputState from '../../../helpers/hooks/useInputState';
import MyButton from '../../../templates/MyButton';
import MySelect from '../../../templates/MySelect';
import {
  createActRequest,
  getSingleActRequest,
} from '../../../store/actions/acts';
import useNavigateSearch from '../../../helpers/hooks/useNavigateSearch';
import EditAct from './components/EditAct';
import { getPrescriptionsRequest } from '../../../store/actions/prescriptions';
import { ROLES } from '../../../helpers/constants';
import requestAllData from '../../../helpers/requestAllData';

function Edit() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const actId = searchParams.get('act_id');
  const navigateSearch = useNavigateSearch();
  const location = useLocation();

  const prescriptions = useSelector((store) => store.prescriptions.prescriptions);
  const prescriptionsLoading = useSelector((store) => store.prescriptions.prescriptionsLoading);

  const profile = useSelector((store) => store.account.profile);
  const isAdmin = useSelector((store) => store.account.isAdmin);
  const role = useSelector((store) => store.account.role);

  const singleAct = useSelector((store) => store.acts.singleAct);
  const creatingActLoading = useSelector((store) => store.acts.creatingActLoading);
  const creatingActError = useSelector((store) => store.acts.creatingActError);

  const [prescription, setPrescription, prescriptionError, setPrescriptionError] = useInputState();
  const [filteredPrescriptions, setFilteredPrescriptions] = useState([]);

  const [toCreate, setToCreate] = useState(false);

  useEffect(() => {
    if (actId) {
      if (actId !== singleAct.id) {
        dispatch(getSingleActRequest({ 'filter-val': { id: actId } }));
      }
    }
  }, [actId]);

  useEffect(() => {
    if (!prescriptions?.items) {
      dispatch(getPrescriptionsRequest(requestAllData));
    }
  }, []);

  useEffect(() => {
    if (toCreate && !creatingActLoading && !creatingActError && singleAct?.id) {
      navigateSearch(location.pathname, { act_id: singleAct.id });
    }
  }, [creatingActLoading]);

  const createDraft = () => {
    if (prescription && prescriptions?.items?.length) {
      const currentPrescription = prescriptions.items.find((item) => item.id === prescription);
      const payload = {
        'predpisan-id': prescription,
        'project-id': currentPrescription['project-id'],
      };
      dispatch(createActRequest(payload));
      setToCreate(true);
    }
  };

  useEffect(() => {
    if (prescriptions?.items?.length) {
      const allowedStatuses = ['active', 'force-active', 'done-part', 'active-force'];
      setFilteredPrescriptions(prescriptions.items.filter((item) => allowedStatuses.includes(item.status)));
    }
  }, [prescriptions]);

  return (
    <div className="single_act_wrapper">
      <SinglePageHeader text={actId ? t('edit_act') : t('add_act')} />
      {![ROLES.stroymanager, ROLES.constructor].includes(role) ? <p>{t('creating_act_only_stroymanager_constructor')}</p>
        : actId ? <EditAct paramsId={actId} />
          : !prescriptionsLoading && !actId && !prescriptions?.items?.length && filteredPrescriptions?.length ? <p>{t('you_are_not_allocated')}</p>
            : (
              <div>
                <div className="single_act_item row">
                  <p className="act_item_title act_margin_right">
                    {t('engineer')}
                    :
                  </p>
                  <p className="act_simple_text">
                    {profile?.fio}
                  </p>
                </div>
                <div className="single_act_item">
                  <p className="act_item_title act_margin_right">
                    {t('prescription')}
                    :
                  </p>
                  <MySelect wrapperClassName="default_size" data={filteredPrescriptions} showKey={['number-out', 'date-out', 'build-obj-title']} buttonText={t('choose_prescription')} value={prescription} onChange={setPrescription} onlyDropDown t={t} />
                </div>

                {prescription && (
                  <div>
                    <MyButton loading={creatingActLoading} onClick={createDraft} text={t('create')} className="display_inline_block margin_top" />
                    <p className="info_text">{t('you_cant_change_predpisan_after')}</p>
                  </div>
                )}
              </div>
            )}
    </div>
  );
}

export default Edit;
